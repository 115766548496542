
import "./dates.css"



function Dates() {
    return (
        <section id="anchor_program" className="section-dates">
            <h3 className="title-date">Programação</h3>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">13 de março</p>
                    <p className="date-title2">Quinta-feira</p>
                </div>
                <div className="date-container-description">
                    <p>20h: Espetáculo “A los amigos”</p>
                    <p style={{ marginTop: 20, fontWeight: 400 }} id="description-gap">
                        O espetáculo será realizado no Teatro dos Bancários, localizado na 314/315 sul em Brasília - DF.
                        <a
                            href="https://maps.app.goo.gl/sezK4NLCMc8PcixB7"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: 10, textDecoration: 'none', fontWeight: 'bold', color: '#007BFF' }}
                        >
                            📍
                        </a>
                    </p>

                    <p>22h - 3h: Milonga de abertura</p>
                    <p>Local: Clube da Aeronáutica de Brasília</p>
                </div>
            </div>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">14 de março</p>
                    <p className="date-title2">Sexta-feira</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Movimentos suaves. Técnicas para interpretar instrumentos melódicos. Sempre buscando a harmonia entre a música e a atitude física. </p>

                    <p>14h40 - 15h40: Anna Elisa e André Carvalho </p>
                    <p id="description-gap">Tema: Movimentos em giros e contra giros no abraço fechado</p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Yasmina Mamana e Muller Dantas</p>
                    <p id="description-gap">Tema: Milonga - Sentindo no corpo o ritmo da dança.</p>

                    <p>17h10 - 18h10: Cynthia Palácios e Sebastian Bolivar</p>
                    <p id="description-gap">Clarisa e Jonathan / Cynthia e Sebastian: Tango para competição com Campeões:
                        Pontos-chave e estratégicos para explorar a nossa dança na fase de competição, tendo em conta nós, o júri e o público.</p>

                    <p id="description-gap">Local de todas as aulas: Clube da Aeronáutica de Brasília</p>

                    <p>22h - 3h: Milonga</p>
                    <p id="description-gap">
                        Local: Clube da Aeronáutica de Brasília</p>
                </div>
            </div>

            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">15 de março</p>
                    <p className="date-title2">Sábado</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: La calecita: eixo, imprivasação e musicalidade.</p>

                    <p>14h30 - 15h30: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Adornos: Vários exercícios e ideias para preencher de cores o nosso baile. Para ambos os papeis. </p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Yasmina Mamana e Muller Dantas</p>
                    <p id="description-gap">Tema: Tango Rítmico, ampliando a sua maneira de bailar - Nível intermediário.</p>


                    <p>17h10 - 18h10: Cynthia Palácios e Sebastian Bolivar</p>
                    <p id="description-gap">Tema: Diferentes tipos de sacadas e efeitos - distintas direções, alturas, dinâmicas e efeitos na perna livre.</p>

                    <p id="description-gap">Local de todas as aulas: Clube da Aeronáutica de Brasília.</p>

                    <p>22h - 3h: Milonga</p>
                    <p id="description-gap">Local: Clube da Aeronáutica de Brasília</p>

                </div>
            </div>


            <div className="container-dates">
                <div className="date-containter">
                    <p className="date-title1">16 de março</p>
                    <p className="date-title2">Domingo</p>
                </div>
                <div className="date-container-description">
                    <p>13h30 - 14h30: Clarisa Aragon e Jonathan Saavedra</p>
                    <p id="description-gap">Tema: Vals: Jogando com a circularidade e os níveis dessa dança incrível.</p>

                    <p>14h30 - 15h30: Anna Elisa e André Carvalho</p>
                    <p id="description-gap">Tema: Ganchos e boleios - como conectar esses movimentos.</p>

                    <p id="description-gap">Intervalo</p>

                    <p>16h - 17h: Cynthia Palácios e Sebastian Bolivar</p>
                    <p id="description-gap">Tema: Laboratório de improvisação: exercícios práticos para treinar o ouvido e a sensibilidade, a comunicação com o par e estimular a criatividade.</p>

                    <p>17h10 - 18h10: Cynthia Palácios e Sebastian Bolivar</p>
                    <p id="description-gap">Tema: Estruturas rítmicas - musicalidade e marca para propostas ritmicas, dissociação de marca e movimento para os líderes.</p>

                    <p id="description-gap">Local de todas as aulas: Clube da Aeronáutica de Brasília</p>

                    <p>22h - 3h: Milonga</p>
                    <p>Local: Clube da Aeronáutica de Brasília</p>
                </div>
            </div>


            <p className="program-description">* programação sujeita a alteração sem aviso prévio</p>
        </section>
    );
}

export default Dates;
