import { useState, useEffect } from "react";
import dancarinos from "../../assets/Dancarinos.svg";
import title from "../../assets/TituloEspetaculo.svg";
import desktopImage from "../../assets/BackGroundDesktop.svg";
import mobileImage from "../../assets/Espetaculomobile.svg";
import "./start.css";

function ShowTango() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section className="responsive-section" style={{ display: "flex" }}>
      <img
        src={isMobile ? mobileImage : desktopImage}
        alt="Fundo do espetáculo de tango"
        className="background-image"
      />
      <a
        href="https://bit.ly/espetaculodetango" target="_blank"
        className="button-espetaculo"
      >
        Garantir meu ingresso para o espetáculo
      </a>
    </section>
  );
}

export default ShowTango;
